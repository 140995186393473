import { z } from 'zod';

export const objToEnum = <T extends Record<string, unknown>>(obj: T) =>
  Object.values(obj) as [T[keyof T]];

export const dateRangeIntervalSchema = z
  .union([
    z.literal('3mo'),
    z.literal('6mo'),
    z.literal('1yr'),
    z.literal('all'),
  ])
  .optional()
  .default('3mo')
  .catch('3mo');
