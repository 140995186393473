import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { getCandidateCommentaryOptions } from 'client/@tanstack/react-query.gen';
import { dateRangeIntervalSchema } from 'helpers/zod';

const schema = z.object({
  period: dateRangeIntervalSchema,
});

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/compare/',
)({
  loader: async ({ context, params }) => {
    const { queryClient } = context;
    const pCommentary = queryClient.ensureQueryData(
      getCandidateCommentaryOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pCommentary]);
  },
  validateSearch: schema,
});
